import React from 'react'
import Helmet from 'react-helmet'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import DefaultLayout from '../layouts/default/defaultLayout'
import ContactsComponents from '../layouts/contacts/ContactsComponents.js'
import { withIntl } from '../i18n'

class ContactsPage extends React.Component {
  state = { title: this.props.intl.messages.puzzleLibPage.title }

  static propTypes = {
    data: PropTypes.object,
    intl: PropTypes.object,
  }

  render() {
    return (
      <DefaultLayout>
        <Helmet
          meta={[{ charset: 'utf-8' }]}
          title={this.state.title}
        />
        <ContactsComponents />
      </DefaultLayout>
    )
  }
}

export default withIntl(injectIntl(ContactsPage))
