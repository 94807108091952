import React, { PureComponent } from 'react'
import style from '../ContactsComponents.module.scss'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import listEvents from '../../../../static/assets/images/contacts/list-of-recommended-measures-to-improve-working-conditions.jpeg'
import statementResults from '../../../../static/assets/images/contacts/summary-statement-of-the-results-of-a-special-assessment-of-working-conditions.jpeg'

class Info extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      data: props.intl.messages.frontpage.contacts.infoLink,
      showModalWindow: false,
      activeImg: ''
    }
    this.map = React.createRef()
  }

  static propTypes = {
    intl: PropTypes.object,
  }

  toggleModal = (bool,img) => {
    this.setState({showModalWindow: bool, activeImg: img});
  }
  
  render() {
    const { intl } = this.props
    const { data } = this.state
    return (
      <div className={classnames(style.contactsContainer, style.container, style.infoCotainer)}>
        <div className={style.infoTitleContainer}>
          <div className={style.infoTitle}>
            {data.workTitle}
            <p><a href={`mailto:${data.workEmail}`} className={style.infoLink}>{data.workEmail}</a></p>
          </div>
          <div className={style.infoTitle}>
            {data.pressTitle}
            <p><a href={`mailto:${data.pressEmail}`} className={style.infoLink}>{data.pressEmail}</a></p>
          </div>
        </div>
        {intl.locale !== 'en' && (
          <div className={style.infoLinkContainer}>
            <button className={style.infoLinkButton} onClick={() => this.toggleModal(true, listEvents)}>{data.workingConditionsLink}</button>
            <button className={style.infoLinkButton} onClick={() => this.toggleModal(true, statementResults)}>{data.statementOfLaborLink}</button>
          </div>
        )}
        {this.state.showModalWindow && 
          <div className={style.infoModal} >
            <img className={style.infoImg} src={this.state.activeImg} alt='Table'/>
            <button className={style.infoModalClose} onClick={() => this.toggleModal(false, '')}></button>
          </div>}
      </div>
    )
  }
}

export default injectIntl(Info)
