import React from 'react'
import Contacts from './components/Contacts'
import Info from './components/Info'
import style from './ContactsComponents.module.scss'
import { injectIntl } from 'react-intl'
import classnames from 'classnames'

class ContactsComponents extends React.Component {
  

  render() {
  
    return (
      <React.Fragment>
        <div className={classnames(style.fontpageBody, style.container)}>
          <div className={style.headerContacts}></div>
          <Contacts />
          <Info />
        </div>
      </React.Fragment>
    )
  }
}

export default injectIntl(ContactsComponents)
